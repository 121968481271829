import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const ProposalsListAll = lazy(() => import('../../../pages/proposals-list-all'));

export const routesManageProposals = {
  'proposals.index': createRoute({
    path: '/proposals/:segment',
    title: 'Proposals',
    component: ProposalsListAll,
    requiredPermissions: ['ui.show.manage-proposals'],
    breadcrumbs: [
      {
        route: 'proposals.index',
        label: 'Manage Proposals',
      },
    ],
  }),
};
