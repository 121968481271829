import { routesUser } from './routes-user';
import { routesDefault } from './routes-default';
import { routesProfile } from './routes-profile';
import { routesPermission } from './routes-permission';
import { routesProject } from './routes-project';
import { routesSalesForce } from './routes-salesforce';
import { routesTeams } from './routes-teams';
import routesDocuments from './routes-files';
import { routesProjectV2 } from './routes-project-v2';
import { routesConfigure } from './routes-configure';
import { routesProducts } from './routes-products';
import { routesManageProposals } from './routes-manage-proposals';

export const routeConfig = {
  ...routesTeams,
  ...routesSalesForce,
  ...routesProject,
  ...routesProjectV2,
  ...routesProducts,
  ...routesManageProposals,
  ...routesConfigure,
  ...routesUser,
  ...routesProfile,
  ...routesPermission,
  ...routesDocuments,
  ...routesDefault, // this one needs to be last !!!!
};
